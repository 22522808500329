import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import { IDoctorVacation } from "../models/DoctorVacation";

export default {
  GetByUser() {
    return AuthAxios.get(ApiURIs.DrvacationListByUser);
  },
  insert(vacation: IDoctorVacation) {
    return AuthAxios.post(ApiURIs.DrvacationInsert, JSON.stringify(vacation));
  },
  update(vacation: IDoctorVacation) {
    return AuthAxios.put(ApiURIs.DrvacationUpdate, JSON.stringify(vacation));
  },
  delete(id: number) {
    return AuthAxios.delete(`${ApiURIs.DrvacationDelete}/${id}`);
  }
};
