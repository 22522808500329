

























































































































































































import { Vue, Component } from "vue-property-decorator";
import { IDoctorVacation } from "../../models/DoctorVacation";
import DoctorVacationService from "../../service/DoctorVacationService";
import AlertService from "../../../common-app/service/AlertService";

@Component
export default class VacationList extends Vue {
  public vacation: IDoctorVacation = {} as IDoctorVacation;
  public vacationObj: IDoctorVacation = {} as IDoctorVacation;
  public vacationList: IDoctorVacation[] = [];
  public submited: boolean = false;
  public currentIndex: number = 0;

  created() {
    DoctorVacationService.GetByUser().then((res) => (this.vacationList = res.data.result || []));
    const dict = {
      custom: {
        title: {
          required: "The Title is required.",
        },
        FromDate: {
          required: "From date is required.",
          afterCurrentDate: "From date must be after current date.",
        },
        ToDate: {
          required: "To date is required.",
          afterCurrentDate: "To date must be after current date.",
          isAfter: "To date must be later than from date.",
        },
      },
    };
    this.$validator.localize("en", dict);
  }

  submitFrom() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.$bvModal
          .msgBoxConfirm("your all active appointment will be canceled during your vacation time, are your sure want to procede ?", {
            title: "Please Confirm",
            okVariant: "success",
            okTitle: "NO",
            cancelVariant: "danger",
            cancelTitle: "YES",
            hideHeaderClose: true,
            headerClass: "justify-content-center",
            bodyClass: "text-center",
            centered: true,
            footerClass: "justify-content-center border-top-0 flex-wrap-reverse",
          })
          .then((value) => {
            if (value == false) {
              this.submited = true;
              DoctorVacationService.insert(this.vacation)
                .then((res) => {
                  this.vacation = {} as IDoctorVacation;
                  this.vacationList.push(res.data.result);
                  this.submited = false;
                  this.$validator.reset();
                  //alert("success");
                  AlertService.sendMessage({ status: true, message: "Vacation added successfully" });
                })
                .catch((err) => {
                  this.submited = false;
                  //alert("faild");
                  AlertService.sendMessage({ status: true, message: "Failed to add vacation, please try again." });
                });
            }
          })
          .catch((err) => {
            // An error occurred
          });
      }
    });
  }

  remove(id: number, index: number) {
    this.$bvModal
      .msgBoxConfirm("Do you really want to delete?", {
        title: "Please Confirm",
        okVariant: "success",
        okTitle: "NO",
        cancelVariant: "danger",
        cancelTitle: "YES",
        hideHeaderClose: true,
        headerClass: "justify-content-center",
        bodyClass: "text-center",
        centered: true,
        footerClass: "justify-content-center border-top-0 flex-wrap-reverse",
      })
      .then((value) => {
        if (value == false) {
          DoctorVacationService.delete(id).then((res) => {
            this.vacationList.splice(index, 1);
            AlertService.sendMessage({ status: true, message: "Vacation deleted successfuly." });
          });
        }
      })
      .catch((err) => {
        // An error occurred
      });
  }

  openModal(vacation: IDoctorVacation, index: number) {
    this.vacationObj = Object.assign({}, vacation);
    this.currentIndex = index;
    this.$bvModal.show("edit-vacation");
  }

  updateFrom(scope: any) {
    this.$validator.validateAll(scope).then((valid) => {
      if (valid) {
        this.$bvModal
          .msgBoxConfirm("Your all active appointment will be cancel during your vacation time , are your sure want to procede ?", {
            title: "Please Confirm",
            okVariant: "success",
            okTitle: "NO",
            cancelVariant: "danger",
            cancelTitle: "YES",
            hideHeaderClose: true,
            headerClass: "justify-content-center",
            bodyClass: "text-center",
            centered: true,
            footerClass: "justify-content-center border-top-0 flex-wrap-reverse",
          })
          .then((value) => {
            if (value == false) {
              this.submited = true;
              DoctorVacationService.update(this.vacationObj).then((res) => {
                this.vacationList[this.currentIndex] = res.data.result;
                this.vacationObj = {} as IDoctorVacation;
                this.submited = false;
                this.$bvModal.hide("edit-vacation");
                this.$validator.reset();
                //alert("success update");
                AlertService.sendMessage({ status: true, message: "Vacation updated successfully" });
              });
            }
          })
          .catch((err) => {
            // An error occurred
          });
      }
    });
  }
}
